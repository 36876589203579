<template>
  <div>
    <div class="container-fluid">
      <PageTitle />

      <template v-if="isList">
        <card-list
          :filter.sync="filter"
          :dataListing.sync="data"
          :idKey="idKey"
          :fields="fields"
        >
          <template #filters>
            <b-col md="3">
              <VSelect
                v-model="filter.kantor"
                placeholder="-- Semua Kantor --"
                :options="mrKantor"
                :reduce="(v) => String(v.value)"
                @input="doFilter"
              />
            </b-col>
            <b-col md="3">
              <VSelect
                v-model="filter.unitkerja"
                placeholder="-- Semua Unit --"
                :options="mrUnitKerja"
                :reduce="(v) => String(v.value)"
                @input="doFilter"
              />
            </b-col>
            <b-col md="2" class="mb5">
              <VSelect
                v-model="filter.level"
                placeholder="-- Semua Level --"
                :options="mrLevel"
                :reduce="(v) => String(v.value)"
                @input="doFilter"
              />
            </b-col>
          </template>
          <template #mo_code="data">
            {{ data.scope.value.toUpperCase() }} - {{data.scope.item.mbl_name.toUpperCase()}} - {{ data.scope.item.mo_name.toUpperCase() }}
          </template>
          <template #bu_unit_kerja="data">
            {{ data.scope.value || (data.scope.item.mbl_name||"").toUpperCase() }}
          </template>
          <template #bu_name_alias="data">
            {{ data.scope.value || "-" }}
          </template>
          <template #bu_is_active="data">
            <LabelStatus :status="data.scope.item[statusKey]" />
            <div v-if="data.scope.item.bu_is_delegated=='Y'">
              <span class="badge badge-info">Didelegasikan</span>
            </div>
            <div v-else-if="data.scope.item.bu_got_delegate=='Y'">
              <span class="badge badge-info">Mendapat Delegasi</span>
            </div>
          </template>
          <template #aksi="data">
            <div 
              v-if="!moduleRole('ViewOnly')"
              class="btn-toolbar"
            >
              <b-dropdown no-flip>
                <template #button-content>
                  <i class="icon-menu"></i>
                </template>
                <b-dropdown-item
                  :to="{
                    name: $route.name,
                    params: { pageSlug: data.scope.item[idKey] },
                  }"
                >
                  Ubah Data
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.scope.item.bu_is_delegated=='N' && 
                  data.scope.item.bu_got_delegate=='N'"
                  @click="doDelete(data.scope.index, data.scope.item)"
                >
                  Hapus
                </b-dropdown-item>
              </b-dropdown>
              <b-dropdown 
                v-if="data.scope.item[statusKey]!=3 && 
                data.scope.item.bu_is_delegated=='N' && 
                data.scope.item.bu_got_delegate=='N'"
                no-flip variant="info" class="ml10" toggle-class="absolute-button"
              >
                <template #button-content>
                  <i
                    class="ti-exchange-vertical"
                    v-b-tooltip.hover
                    title="Ganti Status"
                  ></i>
                </template>
                <b-dropdown-item
                  :class="{ markedDropdown: data.scope.item[statusKey] == 1 }"
                  @click="doChangeStatus(data.scope.index, data.scope.item, 1)"
                >
                  Active
                </b-dropdown-item>
                <b-dropdown-item
                  :class="{ markedDropdown: data.scope.item[statusKey] == 2 }"
                  @click="doChangeStatus(data.scope.index, data.scope.item, 2)"
                >
                  Inactive
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <span v-else>-</span>
          </template>
        </card-list>
      </template>
      <div v-else class="card">
        <div class="card-body">
          <h5 class="card-title mb-3">
            {{ isAdd ? "Tambah User" : "Ubah User" }}
          </h5>

          <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
            <form @submit.prevent="handleSubmit(doSubmitCRUD)">
              <div class="row">
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">
                          Akun Level
                          <span class="text-danger mr5">*</span>
                        </label>
                        <VSelect
                          v-model="row.bu_level"
                          placeholder="-Pilih Akun Level-"
                          :options="mrLevel"
                          :reduce="(v) => v.value"
                          @input="doChangeLevel"
                        />
                        <VValidate
                          name="Akun Level"
                          v-model="row.bu_level"
                          :rules="mrValidation.bu_level"
                        />
                      </div>
                    </div>
                    <template v-if="row.bu_level">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="control-label">
                            Kode Kantor
                            <span class="text-danger mr5">*</span>
                          </label>
                          <VSelect
                            v-model="row.bu_office_id"
                            placeholder="-Pilih Kode Kantor-"
                            :options="mrKantor"
                            :reduce="(v) => v.value"
                            ref="selectOffice"
                            @input="doChangeUnitKerja"
                          />
                          <VValidate
                            name="Kode Kantor"
                            v-model="row.bu_office_id"
                            :rules="mrValidation.bu_office_id"
                          />
                        </div>
                      </div>
                      <div 
                        v-if="(isPusat && !isAdminPusat) || (!isPusat && !isPJRTUorRisk)"
                        class="col-md-12"
                      >
                        <div class="form-group">
                          <label class="control-label">
                            {{ isPusat?'Group':'Unit Kerja' }}
                            <span class="text-danger mr5">*</span>
                          </label>
                          <VSelect
                            v-model="row.bu_unit_kerja_id"
                            :placeholder="'-Pilih '+(isPusat?'Group':'Unit Kerja')+'-'"
                            class="vs__multiple"
                            multiple
                            :options="mrUnitKerja"
                            :reduce="(v) => String(v.value)"
                          />
                          <VValidate
                            :name="isPusat?'Group':'Unit Kerja'"
                            v-model="row.bu_unit_kerja_id"
                            rules="required"
                          />
                        </div>
                      </div>
                    </template>
                  </div>
                  <b-row>
                    <b-col md="6">
                      <div class="form-group">
                        <label class="control-label">
                          Nama Lengkap
                          <span class="text-danger mr5">*</span>
                        </label>
                        <input
                          v-model="row.bu_full_name"
                          type="text"
                          class="form-control"
                          placeholder="e.g. John"
                        />
                        <VValidate
                          name="Nama Lengkap"
                          v-model="row.bu_full_name"
                          :rules="mrValidation.bu_full_name"
                        />
                      </div>
                    </b-col>
                    <b-col md="6">
                      <div class="form-group">
                        <label class="control-label">
                          Nama Alias
                        </label>
                        <input
                          v-model="row.bu_name_alias"
                          type="text"
                          class="form-control"
                          placeholder="e.g. John"
                        />
                        <VValidate
                          name="Nama Alias"
                          v-model="row.bu_name_alias"
                          :rules="mrValidation.bu_name_alias"
                        />
                      </div>
                    </b-col>
                    <b-col md="6">
                      <div class="form-group">
                        <label class="control-label">
                          No Telp/HP
                        </label>
                        <input
                          v-model="row.bu_phone"
                          type="text"
                          class="form-control"
                          placeholder="e.g. 08xxxxx"
                        />
                        <VValidate
                          name="No Telp/HP"
                          v-model="row.bu_phone"
                          :rules="{ regex: /^(\+\d{1,3}[- ]?)?\d{5,13}$/ }"
                        />
                      </div>
                    </b-col>
                    <b-col md="6">
                      <div class="form-group">
                        <label class="control-label">
                          Alamat Email
                          <span class="text-danger mr5">*</span>
                        </label>
                        <input
                          v-model="row.bu_email"
                          type="email"
                          class="form-control"
                          placeholder="e.g. joinemail@example.com"
                        />
                        <VValidate
                          name="Alamat Email"
                          v-model="row.bu_email"
                          :rules="mrValidation.bu_email"
                        />
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <div class="form-group">
                        <label class="control-label">
                          Username
                          <span class="text-danger mr5">*</span>
                        </label>
                        <input
                          v-model="row.bu_username"
                          type="text"
                          class="form-control text-lowercase"
                          placeholder="e.g. john"
                        />
                        <VValidate
                          name="Username"
                          v-model="row.bu_username"
                          :rules="{required: mrValidation.bu_username == 'required', regex: /^(?=.{4,125}$)(?:[a-zA-Z\d]+(?:(?:\.|-|_))*)+$/}"
                        />
                      </div>
                    </b-col>
                  </b-row>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="control-label">
                          Password
                          <span v-if="!row.bu_id" class="text-danger mr5"
                            >*</span
                          >
                          <a href="javascript:;" class="ml-1 icon-information" v-b-tooltip.hover.noninteractive="'Password harus mengandung abjad, angka, dan simbol. Minimal 8 karakter.'"><svg width="23" height="23" viewBox="0 0 20 20" fill="#000" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 17a7 7 0 100-14 7 7 0 000 14zm8-7a8 8 0 11-16 0 8 8 0 0116 0z" clip-rule="evenodd"></path><path d="M10.93 8.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533l1.002-4.705z"></path><circle cx="10" cy="6.5" r="1"></circle></svg></a>
                        </label>
                        <input
                          v-model="row.bu_password"
                          type="password"
                          class="form-control"
                        />
                        <VValidate
                          name="Password"
                          v-model="row.bu_password"
                          :rules="{ required: !row.bu_id, regex: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/ }"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="control-label">
                          Confirm Password
                          <span v-if="!row.bu_id" class="text-danger mr5"
                            >*</span
                          >
                        </label>
                        <input
                          v-model="row.bu_password2"
                          type="password"
                          class="form-control"
                        />
                        <VValidate
                          name="Confirm Password"
                          v-model="row.bu_password2"
                          :rules="{
                            required: !row.bu_id,
                            confirmed: 'Password',
                          }"
                        />
                      </div>
                    </div>
                    <div class="w-100"></div>
                    <div class="col-md-6 mb-2" v-if="!isAdd">
                      <b-button size="sm" @click="generateLink" :variant="'info'">
                        Create Reset Password Link
                      </b-button>
                    </div>
                  </div>

                  <div v-if="row.bu_is_delegated=='N' && row.bu_got_delegate=='N'" class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="control-label">
                          Status
                          <span v-if="row[statusKey] != 3" class="text-danger mr5">*</span>
                        </label>
                        <template v-if="row[statusKey] != 3">
                        <div>
                          <InputRadio
                            v-model="row[statusKey]"
                            name="status"
                            option="1"
                          />
                          <InputRadio
                            v-model="row[statusKey]"
                            name="status"
                            option="2"
                          />
                        </div>
                        <VValidate
                          name="Status"
                          v-model="row[statusKey]"
                          :rules="mrValidation[statusKey]"
                        />
                        </template>
                        <template v-else>
                          <span class="ml5">
                           <LabelStatus :status="2" />
                          </span>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label">
                      Foto Profil
                    </label>
                    <Uploader
                      v-model="row.bu_photo"
                      type="user"
                      label="Foto"
                    />
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-2 offset-md-10">
                  <button
                    type="submit"
                    class="btn btn-block btn-rounded  btn-info"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GlobalVue from "@libs/Global";
import PageTitle from "@view/include/PageTitle";
import CardList from "@/components/CardList";
import Gen from "@/libs/Gen.js";

let _ = global._;
export default {
  extends: GlobalVue,
  components: { PageTitle, CardList },
  data() {
    return {
      idKey: "bu_id",
      statusKey: "bu_is_active",
      fields: [
        { key: "select" },
        { key: "number", label: "#" },
        { key: "bu_code", label: "Kode" },
        { key: "mo_code", label: "Kantor", is_custom: true },
        { key: "bu_unit_kerja", label: "Unit Kerja / Group", is_custom: true },
        { key: "bul_level_name", label: "Akun Level" },
        {
          key: "bu_full_name",
          label: "Nama Lengkap",
          tdClass: "width_120",
          thClass: "width_120",
        },
        /* {key:'bu_name_alias', label:'Nama Alias', is_custom:true}, */
        { key: "bu_username", label: "Username" },
        {
          key: "bu_is_active",
          label: "Status",
          is_custom: true,
          tdClass: "bg_selected",
        },
        {
          key: "aksi",
          is_custom: true,
          style: { background: "red" },
          tdClass: "width_170",
          thClass: "width_170",
        },
      ],
      mrKantor: [],
      mrKantorTemp: [],
      mrUnitKerja: [],
      mrUnitKerjaTemp: [],
      mrLevel: [],
      username: '',
      longLink: 0,
    };
  },
  methods: {
    doChangeLevel(){
      if(this.isAdminPusat){
        let kantorPusat = _.filter(this.mrKantorTemp, (v)=>{
          return v.level=='pusat'
        })
        this.$set(this,'mrKantor',kantorPusat)
      }else{
        this.$set(this,'mrKantor',this.mrKantorTemp)
      }
    },
    doChangeUnitKerja() {
      let LevelKantor = _.map(_.filter(this.mrKantor, (v) => {
        return v.value == this.row.bu_office_id;
      }), (v)=>{
        return v.level
      })
      LevelKantor = LevelKantor=='pusat'?'divisi':LevelKantor
      let FilterUnit = _.filter(this.mrUnitKerjaTemp, (v)=>{
        return v.level==LevelKantor
      })
      this.$set(this,'mrUnitKerja',FilterUnit)
    },

    generateUsername(){
      const akunLevel = this.mrLevel.find(v => v.value == this.row.bu_level);
      const kodeKantor = this.mrKantor.find(v => v.value == this.row.bu_office_id);
      this.$set(this.row, 'bu_username', `${akunLevel ? akunLevel.label.slugify('_') : ''}.${kodeKantor ? kodeKantor.mo_name.slugify('_') : ''}`)
    },
    generateLink(){
      global.Swal.fire({
        title: 'Yakin membuat link reset password untuk akun ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#30d64c',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Ya, Yakin!',
        cancelButtonText: 'Batal',
      }).then(result => {
        if(result.value){
          Gen.apiRest('/do/create-reset-link', { data: {username: this.username} }, 'POST')
          .then(res => {            
            console.log(res)
            global.Swal.fire({
              title: 'Link Berhasil Dibuat',
              icon: 'success',
              html:`Silakan copy dan kirimkan link verifikasi berikut ini. Hanya berlaku selama <strong>${this.longLink} Menit.</strong>
              <div class="input-group mt-3">
                <input class="form-control" id="link" value="${res.data}"> 
                <div class="input-group-append">
                  <button class="btn btn-success" onclick="copy_link(this)" type="button"><i class="ti-link"></i></button>
                </div>
              </div>
                
              `,
            })
          }).catch(err => {
            console.log(err)
          })
        }
      })
    },

  },
  computed: {
    isPusat() {
      return _.filter(this.mrKantor, (v) => {
        return v.value == this.row.bu_office_id && v.level=='pusat';
      }).length;
    },
    isPJRTUorRisk(){
      return _.filter(this.mrLevel, (v) => {
        return v.value == this.row.bu_level && 
        ((v.role_rel||[]).indexOf("isPJRTU")>-1 || (v.role_rel||[]).indexOf("isRiskOfficer")>-1);
      }).length;
    },
    isAdminPusat(){
      return _.filter(this.mrLevel, (v) => {
        return v.value == this.row.bu_level && 
        (
          (v.role_rel||[]).indexOf("isAdministratorSystem")>-1 ||
          (v.role_rel||[]).indexOf("isAuditor")>-1
        );
      }).length;
    }
  },
  mounted() {
    this.apiGet();

    window.copy_link = function(e){

      function unsecuredCopyToClipboard(text) {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
        } catch (err) {
          console.error('Unable to copy to clipboard', err);
        }
        document.body.removeChild(textArea);
      }

      console.log(e)
      const text = document.querySelector('#link')

      if (window.isSecureContext && navigator.clipboard) {
        navigator.clipboard.writeText(text.value);
      } else {
        unsecuredCopyToClipboard(text.value);
      }
      e.innerHTML = 'copied'

      
    }

  },
  watch: {
    $route() {
      this.apiGet();
    },
    'row.bu_level'(v){
      if(this.isAdd && v && this.row.bu_office_id){
        this.generateUsername()
      }
    },
    'row.bu_office_id'(v){
      if(this.isAdd && v && this.row.bu_level){
        this.generateUsername()
      }
    }
  },
};
</script>
